<template>
  <app-modal
    :title="$t('quiz.quiz_article_modal_header')"
    modal-size="modal-md"
    @close="close"
    close-on-click-self
  >
    <!-- Body -->
    <template slot="body">
      <div class="text-center">
        <button
          type="button"
          class="btn btn-green"
          @click="create"
          data-test="modal_button_create"
        >
          {{ $t('quiz.yes') }}
        </button>
        <button
          type="button"
          class="btn btn-inverse"
          @click="close"
          data-test="modal_button_close"
        >
          {{ $t('quiz.no') }}
        </button>
      </div>
    </template>
  </app-modal>
</template>

<script>
import Modal from '../shared/Modal'

export default {
  name: 'QuizCreateArticleModal',
  components: {
    appModal: Modal
  },
  methods: {
    close () {
      this.$emit('close')
    },
    create () {
      this.$emit('create')
    }
  }
}
</script>
