<script>
import QuizNewView from './QuizNewView'

export default {
  name: 'QuizEditView',
  extends: QuizNewView,
  mounted () {
    const previousTabIndex = this.$store.getters['quiz/previousTabIndex']
    this.$refs.quizForm.tabClick(previousTabIndex)
    this.$store.commit('quiz/setPreviousTabIndex', 0)
    this.getQuiz()
  }
}
</script>
