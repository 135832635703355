<template>
  <div>
    <div class="main-heading-text">
      {{ $t('quiz.question_list') }}
    </div>
    <div
      v-for="(question, qIndex) in quiz.questions"
      :key="`question-${qIndex}`"
      class="questions"
    >
      <div class="questions__heading">
        <div class="questions__heading__text">
          {{ $t('quiz.question_number', { number: qIndex + 1 }) }}
        </div>
        <ModuleFormButton
          v-if="!disabled"
          icon="delete"
          @click="onDeleteQuestion(qIndex)"
        />
      </div>
      <Input
        v-model="question.title"
        :id="`question_title-${qIndex}`"
        @blur="$v.quiz.questions.$each[qIndex].title.$touch()"
        :error="$v.quiz.questions.$each[qIndex].title.$error"
        :label="$t('quiz.question_title')"
        :disabled="disabled"
      />
      <Textarea
        v-model="question.description"
        :id="`question_description-${qIndex}`"
        :label="$t('quiz.question_description')"
        :rows=2
        :disabled="disabled"
      />
      <div class="title-text">
        {{ $t('quiz.question_image') }}
      </div>
      <ModuleFormPhotoBox
        :image="question.imageInfo"
        :disabled="disabled"
        :media-usage-type="mediaUsageTypes.question"
        @set-media="(medias) => setImage(question, medias)"
        @remove-media="removeImage(question)"
        buttons-only
      />
      <div v-if="!$v.quiz.questions.$each[qIndex].answers.required || !$v.quiz.questions.$each[qIndex].answers.minLength"
           class="error-text">
        {{ $t('quiz.enter_minimum_two_answers') }}
      </div>
      <div
        v-for="(answer, aIndex) in question.answers"
        :key="`question-${qIndex}-answer-${aIndex}`"
        class="answers"
      >
        <div class="answers__heading">
          <div class="answers__heading__text">
            {{ $t('quiz.answer_number', { number: aIndex + 1 }) }}
          </div>
          <ModuleFormButton
            v-if="!disabled"
            icon="delete"
            @click="removeAnswer(question, aIndex)"
          />
        </div>
        <div class="answers__inputs">
          <Input
            v-model="answer.title"
            :id="`question-${qIndex}-answer_title-${aIndex}`"
            @blur="$v.quiz.questions.$each[qIndex].answers.$each[aIndex].title.$touch()"
            :error="$v.quiz.questions.$each[qIndex].answers.$each[aIndex].title.$error"
            :label="$t('quiz.answer_title')"
            :disabled="disabled"
            class="answers__inputs__title"
          />
          <Input
            v-model="answer.points"
            :id="`question-${qIndex}-answer_points-${aIndex}`"
            :label="$t('quiz.answer_points')"
            type="number"
            :disabled="disabled"
            @input="$emit('answer-points-change')"
            class="answers__inputs__points"
          />
        </div>
        <div class="title-text">
          {{ $t('quiz.answer_image') }}
        </div>
        <ModuleFormPhotoBox
          :image="answer.imageInfo"
          :disabled="disabled"
          :media-usage-type="mediaUsageTypes.answer"
          @set-media="(medias) => setImage(answer, medias)"
          @remove-media="removeImage(answer)"
          buttons-only
        />
      </div>
      <button
        v-if="!disabled"
        class="add-button "
        :title="$t('quiz.add_answer')"
        @click="addAnswer(question)"
      >
        <PlusIcon class="add-button__icon"/>
        {{ $t('quiz.add_answer') }}
      </button>
    </div>
    <div class="button-container">
      <button
        :disabled="disabled"
        :class="[{ 'add-button--disabled': disabled }, 'add-button questions__add-button']"
        :title="$t('quiz.add_question')"
        @click="addQuestion()"
      >
        <PlusIcon class="add-button__icon"/>
        {{ $t('quiz.add_question') }}
      </button>

      <button
        type="button"
        class="btn btn-sm btn-green btn-generate quiz-form__generator-button"
        :class="{ 'btn-generate--disabled': disabled }"
        @click="handleGenerateQuestion()"
      >
      <span class="btn-generate__content">
     <IconMagicWand
       class="btn-generate__icon"
       :class="{ 'loading-animation': isGenerating }"
     />
      </span>
        <span class="btn-generate__label">
        {{ $t('quiz.quiz_generate_question') }}
      </span>
      </button>
    </div>

  </div>
</template>

<script>
import ModuleFormPhotoBox from '@/components/shared/ModuleFormPhotoBox'
import { maxLength, minLength, required } from 'vuelidate/lib/validators'
import Input from '@/components/form/inputs/Input'
import Textarea from '@/components/form/Textarea'
import PlusIcon from '@/assets/img/svg/plus.svg?inline'
import QuizAnswerModel from '@/model/QuizAnswerModel'
import QuizQuestionModel from '@/model/QuizQuestionModel'
import ModuleFormButton from '@/components/shared/ModuleFormButton'
import {
  MEDIA_USAGE_TYPE_QUIZ_ANSWER,
  MEDIA_USAGE_TYPE_QUIZ_QUESTION
} from '@/model/ValueObject/MediaUsageTypes'
import IconMagicWand from '@/assets/img/svg/wand-magic-sparkles-solid.svg?inline'
import NotifyService from '@/services/NotifyService'

export default {
  name: 'QuizFormQuestionTabLeftColumn',
  props: {
    quiz: {
      type: Object,
      required: true
    },
    disabled: {
      type: Boolean,
      default: false
    },
    isGenerating: {
      type: Boolean,
      default: false
    }
  },
  components: {
    ModuleFormButton,
    ModuleFormPhotoBox,
    Input,
    Textarea,
    IconMagicWand,
    PlusIcon
  },
  data () {
    return {
      mediaUsageTypes: {
        question: MEDIA_USAGE_TYPE_QUIZ_QUESTION,
        answer: MEDIA_USAGE_TYPE_QUIZ_ANSWER
      }
    }
  },
  validations: {
    quiz: {
      questions: {
        $each: {
          title: {
            required,
            minLength: minLength(3),
            maxLength: maxLength(250)
          },
          answers: {
            required,
            minLength: minLength(2),
            $each: {
              title: {
                required,
                minLength: minLength(1)
              }
            }
          }
        }
      }
    }
  },
  computed: {
    quizTheme: {
      get () {
        return this.$store.state.quiz.quizTheme
      }
    }
  },
  methods: {
    setImage (questionOrAnswer, medias) {
      medias.forEach(media => {
        questionOrAnswer.imageInfo = media
        questionOrAnswer.image = media.id
      })
    },
    removeImage (questionOrAnswer) {
      questionOrAnswer.image = null
      questionOrAnswer.imageInfo = null
    },
    async handleGenerateQuestion () {
      if ((!this.quizTheme || this.quizTheme.length < 3) && (!this.quiz.title || this.quiz.title.length < 3)) {
        NotifyService.setErrorMessage(this.$t('quiz.notify.quiz_fill_topic'))
        return
      }
      var quizData = {
        quizTitle: this.quizTheme || this.quiz.title,
        quizQuestions: [
        ]
      }
      quizData.quizQuestions = this.getQuizQuestionTitles()

      this.fetchAndProcessQuizQuestionByTheme(quizData)
        .then(this.processQuizQuestionByThemeResult)
        .catch(error => {
          console.error(error)
          NotifyService.setErrorMessage(error)
        })
    },
    getQuizQuestionTitles () {
      const questionTitles = []

      if (this.quiz && Array.isArray(this.quiz.questions) && this.quiz.questions.length > 0) {
        this.quiz.questions.forEach(question => {
          if (question.title) {
            questionTitles.push(question.title)
          }
        })
      }

      return questionTitles
    },
    async fetchAndProcessQuizQuestionByTheme (quizData) {
      try {
        const result = await this.$store.dispatch('quiz/fetchQuizQuestionByTheme', quizData)
        return result
      } catch (error) {
        throw new Error(this.$t('notify.fetch_quiz_error'))
      }
    },
    processQuizQuestionByThemeResult (results) {
      const newQuestion = this._.cloneDeep(QuizQuestionModel)
      newQuestion.title = results.question
      newQuestion.description = results.trueAnswerParagraph

      for (let i = 0; i < results.falseAnswers.length; i++) {
        const answer = this._.cloneDeep(QuizAnswerModel)
        answer.title = results.falseAnswers[i]
        newQuestion.answers.push(answer)
      }

      const correctAnswer = this._.cloneDeep(QuizAnswerModel)
      correctAnswer.title = results.trueAnswers[0]
      correctAnswer.points = 1
      newQuestion.answers.push(correctAnswer)

      this.quiz.questions.push(newQuestion)
    },
    addQuestion () {
      const newQuestion = this._.cloneDeep(QuizQuestionModel)
      this.addAnswer(newQuestion)
      this.addAnswer(newQuestion)
      this.quiz.questions.push(newQuestion)
    },
    onDeleteQuestion (index) {
      this.quiz.questions.splice(index, 1)
    },
    addAnswer (question) {
      question.answers.push(this._.cloneDeep(QuizAnswerModel))
    },
    removeAnswer (question, index) {
      question.answers.splice(index, 1)
    }
  },
  created () {
    if (!this.quiz.id) {
      this.addQuestion()
    }
  }
}
</script>

<style scoped lang="scss">
.main-heading-text {
  @include font(400 22px "Roboto");
  color: #465674;
  margin-bottom: rem(10px);
}
.title-text {
  color: #8A96AC;
  font-size: rem(14px);
}
.error-text {
  color: #dc3545;
  font-size: rem(14px);
  margin-top: rem(10px);
}

.questions {
  background-color: #FFFFFF;
  margin-bottom: 1rem;
  padding: rem(10px);
  border-radius: rem(6px);

  &__heading {
    display: flex;

    &__text {
      flex: 0 0 calc(100% - 25px);
      @include font(700 16px "Roboto");
      color: #465674;
      text-transform: uppercase;
      margin-bottom: rem(10px);
    }
  }

  &__add-button {
    width: 100%;
  }
}

.answers {
  background-color: #F2F3F7;
  margin-top: 1rem;
  padding: rem(10px);
  border-radius: rem(6px);

  &__heading {
    display: flex;

    &__text {
      flex: 0 0 calc(100% - 25px);
      @include font(400 16px "Roboto");
      color: #465674;
      margin-bottom: rem(8px);
    }
  }

  &__inputs {
    display: flex;
    gap: 2%;

    &__title {
      flex: 0 0 74%;
    }

    &__points {
      flex: 0 0 24%;
    }
  }
}

.button-container {
  display: flex;
  gap: 15px;
  flex-wrap: wrap;
}
.btn-generate, .add-button {
  @include font(700 13px "Roboto");
  @include padding(7px 14px);
  @include radius(4px);
  display: flex;
  flex: 1 1 calc(50% - 15px);
  min-width: 155px;
  max-width: calc(50% - 15px);
  height: rem(30px);
  margin-top: 1rem;
  cursor: pointer;
  align-items: center;
  justify-content: center;
  &--disabled {
    cursor: default !important;
    opacity: 1 !important;
  }
}

.add-button {
  background: #6599fe;
  color: #fff;
  border: none;
  transition: background 200ms;
  justify-content: center;
  @include bp(10) {
    &:not(.add-button--disabled):hover {
      background: darken(#6599fe, 10%);
    }
  }
  &--disabled {
    background: rgba(101, 153, 254,0.5);
  }
  &__icon {
    @include size(rem(14px));
    fill: #FFFFFF;
    float: left;
    margin-right: rem(8px);
  }
}

.btn-generate {
  gap: rem(5px);
  &--disabled {
    background: rgba(65, 184, 131, 0.5);
  }
  &__content {
    width: rem(17px);
    height: rem(17px);
  }
  &__icon {
    fill: #FFFFFF;
  }
}
.loading-animation {
  animation: blick-load 1.8s linear infinite;
}
@keyframes blick-load {
  0% {
    fill: rgba(220, 47, 67, 0.68);
    transform:rotate(0deg);
  }
  50% {
    fill: #636de5;
  }
  100% {
    fill: rgba(220, 47, 67, 0.68);
    transform:rotate(360deg);
  }
}
</style>
