<template>
  <section>
    <button
      type="button"
      class="create-button"
      @click="create"
    >
      <span class="create-button__title">
        {{ $t('quiz.create_quiz_article') }}
      </span>
    </button>
    <AlertModal
      v-if="alertModal"
      @close="closeAlertModal"
      @create="confirmAlertModal"
    />
    <ArticleCreateModal
      v-if="articleCreateModal"
      :quiz-article-to-create="quizArticleToCreate"
      @close="closeArticleCreateModal"
    />
    <MediaEditModal
      ref="mediaEditModal"
      v-if="mediaEditModal"
      :selectedMedia="[quiz.imageInfo]"
      :site="site"
      :media-usage-type="mediaUsageTypeArticleHero"
      :insert-button-label="$t('dam.insert_into_article_hero')"
      :force-selecting-site="!site"
      @add-media="setArticleHeroImage"
      @close="closeMediaEditModal"
    >
      <template #topOfBody>
        <div class="label label-info p-10 m-b-10" >
          <i class="fas fa-info-circle"></i> {{ $t('quiz.create_quiz_article_info') }}
        </div>
      </template>
    </MediaEditModal>
  </section>
</template>

<script>
import AlertModal from '@/components/quiz/QuizCreateArticleModal'
import ArticleCreateModal from '@/components/article/ArticleCreateModal'
import MediaEditModal from '@/components/shared/MediaEditModal'
import { MEDIA_USAGE_TYPE_ARTICLE_HERO } from '@/model/ValueObject/MediaUsageTypes'

export default {
  name: 'QuizCreateArticleButton',
  props: {
    quiz: {
      type: Object,
      required: true
    },
    site: {
      type: [Number, Object],
      default: null
    }
  },
  data () {
    return {
      quizArticleToCreate: { field: {} },
      alertModal: false,
      articleCreateModal: false,
      mediaEditModal: false,
      mediaUsageTypeArticleHero: MEDIA_USAGE_TYPE_ARTICLE_HERO
    }
  },
  computed: {
    currentUser () {
      return this.$store.getters['user/currentUser']
    }
  },
  components: {
    ArticleCreateModal,
    AlertModal,
    MediaEditModal
  },
  methods: {
    mapQuizToArticle (newHeroImage, site) {
      const mainRubric = site === this.currentUser.defaultSite ? this.currentUser.defaultRubric : null
      return {
        site: site ?? this.currentUser.defaultSite,
        mainRubric,
        quiz: this.quiz.id,
        field: {
          title: this.quiz.title,
          shortTitle: this.quiz.title,
          subTitle: this.quiz.title,
          firstParagraph: this.quiz.description
        },
        heroImageId: newHeroImage?.id ?? null,
        categories: this.quiz.categories.map(category => category.id),
        setting: {
          editorialArticle: false,
          internalAuthor: false
        },
        sharedUsers: [],
        authors: []
      }
    },
    create () {
      if (this.quiz.enabled) {
        if (this.quiz.imageInfo) {
          this.showMediaEditModal()
        } else {
          this.createQuizArticle()
        }
      } else {
        this.showAlertModal()
      }
    },
    confirmAlertModal () {
      if (this.quiz.imageInfo) {
        this.showMediaEditModal()
      } else {
        this.createQuizArticle()
      }
    },
    setArticleHeroImage (medias) {
      const heroImage = medias[0]
      this.createQuizArticle(heroImage)
    },
    async createQuizArticle (heroImage) {
      this.closeAlertModal()
      if (this.quiz.enabled === false) {
        this.$emit('set-enabled-and-save')
      }
      this.quizArticleToCreate = this.mapQuizToArticle(heroImage, heroImage?.site)
      this.showArticleCreateModal()
    },
    showAlertModal () {
      this.alertModal = true
    },
    closeAlertModal () {
      this.alertModal = false
    },
    showArticleCreateModal () {
      this.articleCreateModal = true
    },
    closeArticleCreateModal () {
      this.articleCreateModal = false
    },
    showMediaEditModal () {
      this.mediaEditModal = true
      this.$nextTick(() => {
        this.$refs.mediaEditModal.setLoading(false) // don't load the image as the user needs to set the 'site' first
      })
    },
    closeMediaEditModal () {
      this.mediaEditModal = false
    }
  }
}
</script>

<style scoped lang="scss">
.create-button {
  border-radius: rem(4px);
  border: rem(1px) solid #D1DBE4;
  cursor: pointer;
  background-color: #41B883;
  color: #FFFFFF;
  height: rem(28px);
  &__title {
    padding-left: rem(5px);
    padding-right: rem(5px);
    font-size: rem(13px);
    font-weight: 700;
  }
}
</style>
