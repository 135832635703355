<template>
  <section>
    <div
      v-if="!vlm"
      class="quiz-form__generator-wrapper form-group"
    >
      <Input
        v-model="quizTheme"
        id="quiz_theme"
        :label="$t('quiz.quiz_topic')"
        :placeholder="$t('quiz.quiz_topic_placeholder')"
        :disabled="disabled"
        :disableFormGroup="true"
        @input="emitQuizThemeChange"
        class="quiz-form__generator-input"
      />
      <div class="quiz-form__generator-button-wrapper">
        <ButtonBase class="btn-generate" variant="success" :title="$t('quiz.quiz_generate')" @click="handleGenerateQuiz" :disabled="disabled">
          <template v-slot:icon>
            <IconMagicWand
              class="btn-generate__icon"
              :class="{ 'loading-animation-ai': isGenerating }"
            />
          </template>
        </ButtonBase>

        <div class="quiz-form__generator-button-info m-t-5">
          <i class="fas fa-hourglass-start m-r-5"></i> {{ $t('quiz.quiz_generate_label') }}
        </div>
      </div>
    </div>

    <Input
      v-model="quiz.title"
      @blur="$v.quiz.title.$touch()"
      :error="$v.quiz.title.$error"
      id="quiz_title"
      :label="$t('quiz.title')"
      :placeholder="$t('quiz.title_placeholder')"
      :disabled="disabled"
    />
    <div class="form-group">
    <label>{{ $t('quiz.categories') }}</label>
      <Multiselect
        v-model="quiz.categories"
        :options="categories"
        label="title"
        track-by="id"
        id="quiz_categories"
        :disabled="disabled"
      />
    </div>
    <Textarea
      v-model="quiz.description"
      id="quiz_description"
      :label="$t('quiz.description')"
      :disabled="disabled"
      :rows=3
    />
    <div class="three-columns">
      <Select
        v-model="quiz.type"
        :options="quizTypeValuesFiltered"
        id="quiz_type"
        :label="$t('quiz.type')"
        :disabled="disabled"
        class="three-columns__column"
        disable-form-group
        no-empty-value
      />
      <Select
        v-model="quiz.setting.layout"
        :options="layouts"
        id="quiz_settings_layout"
        :label="$t('quiz.layout')"
        :disabled="disabled"
        class="three-columns__column"
        disable-form-group
        no-empty-value
      />
      <Input
        v-if="showQuestionsPerPage"
        type="number"
        v-model="quiz.setting.questionsPerPage"
        id="quiz_settings_questionsPerPage"
        :label="$t('quiz.questions_per_page')"
        class="three-columns__column"
        disable-form-group
        :disabled="disabled"
      />
    </div>
    <div v-if="deprecatedQuizTypeInfo" class="label label-info">
      <i class="fas fa-info-circle" />
      {{ $t('quiz.deprecated_quiz_type_info') }}
    </div>
    <div v-if="deprecatedQuizLayoutInfo" class="label label-info">
      <i class="fas fa-info-circle" />
      {{ $t('quiz.deprecated_quiz_layout_info') }}
    </div>
    <OutlinedCheckbox
      v-model="quiz.setting.showImage"
      id="quiz_setting_showImage"
      :label="$t('quiz.show_image')"
      :disabled="disabled"
    />
    <!-- hide till CMS-541 is resolved -->
    <div v-if="false" class="published-dates">
      <Datepicker
        class="published-dates__datepicker"
        v-model="quiz.publishedSince"
        id="quiz_publishedSince"
        :label="$t('quiz.published_since')"
        :placeholder="$t('quiz.select_date')"
        :show-set-now="!disabled"
        :disabled="disabled"
      />
      <Datepicker
        class="published-dates__datepicker"
        v-model="quiz.publishedUntil"
        id="quiz_publishedUntil"
        :label="$t('quiz.published_until')"
        :placeholder="$t('quiz.select_date')"
        :disabled="disabled"
      />
    </div>
  </section>
</template>

<script>
import { mapGetters } from 'vuex'
import { maxLength, minLength, required } from 'vuelidate/lib/validators'
import QuizTypeMixin, {
  QUIZ_TYPE_CORRECT_ANSWER,
  QUIZ_TYPE_PERSONALITY
} from '@/components/mixins/valueObject/QuizTypeMixin'
import { AVAILABLE_LAYOUTS, TYPE_IMAGE_ANSWERS, TYPE_TEXT_ANSWERS } from '@/model/ValueObject/QuizLayout'
import NotifyService from '@/services/NotifyService'
import Input from '@/components/form/inputs/Input'
import Multiselect from '@/components/form/select/MultiSelect'
import Textarea from '@/components/form/Textarea'
import Select from '@/components/form/select/Select'
import Datepicker from '@/components/form/Datepicker'
import OutlinedCheckbox from '@/components/form/OutlinedCheckbox'
import IconMagicWand from '@/assets/img/svg/wand-magic-sparkles-solid.svg?inline'
import ButtonBase from '@/components/buttons/ButtonBase.vue'

export default {
  name: 'QuizFormMainTabLeftColumn',
  mixins: [QuizTypeMixin],
  props: {
    quiz: {
      type: Object,
      required: true
    },
    savedQuizType: {
      type: String,
      required: false
    },
    savedQuizLayout: {
      type: String,
      required: false
    },
    disabled: {
      type: Boolean,
      default: false
    },
    isGenerating: {
      type: Boolean,
      default: false
    }
  },
  components: {
    OutlinedCheckbox,
    Select,
    Textarea,
    Multiselect,
    Input,
    Datepicker,
    IconMagicWand,
    ButtonBase
  },
  data () {
    return {
      showQuestionsPerPage: false
    }
  },
  computed: {
    ...mapGetters(['vlm']),
    categories () {
      return this.$store.getters['category/all']
    },
    quizTypeValuesFiltered () {
      const allowedTypes = [QUIZ_TYPE_CORRECT_ANSWER]
      if (this.savedQuizType === QUIZ_TYPE_PERSONALITY) {
        // 'personality' type is deprecated
        allowedTypes.push(QUIZ_TYPE_PERSONALITY)
      }
      return this.quizTypeValues.filter(item => allowedTypes.includes(item.id))
    },
    deprecatedQuizTypeInfo () {
      return this.savedQuizType === QUIZ_TYPE_PERSONALITY && this.quiz.type !== QUIZ_TYPE_PERSONALITY
    },
    layouts () {
      const allowedLayouts = [TYPE_TEXT_ANSWERS]
      if (this.savedQuizLayout === TYPE_IMAGE_ANSWERS) {
        // 'image-answers' layout is deprecated
        allowedLayouts.push(TYPE_IMAGE_ANSWERS)
      }
      return AVAILABLE_LAYOUTS.filter(item => allowedLayouts.includes(item.id))
    },
    deprecatedQuizLayoutInfo () {
      return this.savedQuizLayout === TYPE_IMAGE_ANSWERS && this.quiz.setting.layout !== TYPE_IMAGE_ANSWERS
    },
    quizTheme: {
      get () {
        return this.$store.state.quiz.quizTheme
      },
      set (value) {
        this.$store.commit('quiz/setQuizTheme', value)
      }
    }
  },
  methods: {
    handleGenerateQuiz () {
      if (this.quizTheme.length < 3) {
        NotifyService.setErrorMessage(this.$t('quiz.notify.quiz_fill_topic'))
      } else {
        this.$emit('generate-quiz', this.quizTheme)
      }
    },
    emitQuizThemeChange (value) {
      this.$emit('update-quiz-theme', this.quizTheme)
    }
  },
  validations: {
    quiz: {
      title: {
        required,
        minLength: minLength(3),
        maxLength: maxLength(250)
      }
    }
  }
}
</script>

<style scoped lang="scss">
@import '@/assets/scss/animations/animation';

.quiz-form {
  &__generator-wrapper {
    display: flex;
    align-items: flex-end;
    gap: rem(17px);
  }
  &__generator-input {
    flex: 1;
    padding-bottom: rem(23px);
  }
  &__generator-button-wrapper {
    flex: 0 0 auto;
  }
  &__generator-button {
    max-height: rem(40px);
    padding: rem(10px);
    font-size: rem(13px);
  }
  &__generator-button-info {
    font-family: "Roboto", sans-serif;
    font-size: rem(12px);
    font-weight: 400;
    color: #8A96AC;
    text-align: center;
  }
}
.published-dates {
  display: flex;
  gap: 2%;
  &__datepicker {
    flex: 0 0 49%;
  }
}
.three-columns {
  display: flex;
  gap: 2%;
  @include bp(0 7) {
    flex-wrap: wrap;
  }
  &__column {
    @include bp(0 7) {
      flex: 0 0 100%;
    }
    @include bp(11) {
      flex: 0 0 32%;
    }
  }
}
.btn-generate {
  &__icon {
    fill: #FFFFFF;
  }
}
</style>
