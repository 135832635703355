<template>
  <button class="button" :class="classObject" @click="$emit('click')">
    <span v-if="hasIconSlot" class="button__icon">
      <slot name="icon"></slot>
    </span>
    <span>{{ title }}</span>
  </button>
</template>

<script>
export default {
  name: 'ButtonBase',
  props: {
    title: {
      type: String,
      default: ''
    },
    variant: {
      type: String,
      required: true,
      validator: (value) => ['primary', 'secondary', 'success', 'danger'].includes(value)
    },
    rounded: {
      type: Boolean,
      default: true
    },
    disabled: {
      type: Boolean,
      default: false
    }
  },
  computed: {
    classObject () {
      const list = [`button--variant-${this.variant}`]

      this.rounded && list.push('button--is-rounded')
      this.disabled && list.push('button--is-disabled')

      return list
    },
    hasIconSlot () {
      return !!this.$slots.icon
    }
  }
}
</script>

<style scoped lang="scss">
.button {
  align-items: center;
  border: none;
  cursor: pointer;
  display: flex;
  font-size: rem(13px);
  gap: rem(10px);
  height: rem(40px);
  padding: rem(10px);

  &:hover, &:focus {
    opacity: 1;
    -webkit-box-shadow: 0 14px 26px -12px var(--shadow-color-1), 0 4px 23px 0 var(--shadow-color-2), 0 8px 10px -5px var(--shadow-color-3);
    box-shadow: 0 14px 26px -12px var(--shadow-color-1), 0 4px 23px 0 var(--shadow-color-2), 0 8px 10px -5px var(--shadow-color-3);
  }

  @include bp(0 7) {
    flex: 0 0 100%;
  }

  &__icon {
    display: flex;
    align-items: center;
    justify-content: center;
    flex: 0 0 auto;
    min-width: 16px;
  }

  &--variant-primary {
    background-color: #007bff;
    color: #fff;
  }

  &--variant-secondary {
    background-color: #6c757d;
    color: #fff;
  }

  &--variant-success {
    background-color: #41b883;
    color: #fff;

    --shadow-color-1: rgba(0, 89, 3, 0.42);
    --shadow-color-2: rgba(0, 89, 3, 0.12);
    --shadow-color-3: rgba(0, 87, 3, 0.2);
  }

  &--variant-danger {
    background-color: #dc3545;
    color: #fff;
  }

  &--is-rounded {
    border-radius: rem(4px);
  }

  &--is-disabled {
    opacity: 0.5;
    pointer-events: none;
  }
}
</style>
